<template>
  <div class="timeline">
    <template v-if="items.length">
      <div
        class="timeline__vertical-divider"
        :style="{ height: verticalDividerHeight }"
      ></div>
      <div
        class="timeline__item"
        v-for="(item, index) in items"
        :key="`item-${index}`"
      >
        <div class="timeline__indicator">
          <div class="timeline__indicator-date">
            {{ getMonthDay(item.date) }}
          </div>
          <div class="timeline__indicator-time">{{ getTime(item.date) }}</div>
        </div>
        <div class="timeline__horizontal-divider"></div>
        <div v-if="item.flag" class="timeline__flag">
          <Pill :count="item.flag" :label="true" />
        </div>

        <SlideToggle
          :disabled="!item.body || !item.body.length"
          @on-slide-toggle="onSlideToggle($event, index)"
        >
          <template v-slot:header="slotProps">
            <div class="timeline__item-heading">
              {{ item.heading }}
            </div>
            <div class="timeline__item-controls">
              <span class="timeline__item-label" v-if="item.label">{{
                item.label
              }}</span>
              <span
                class="timeline__item-description"
                v-if="item.description"
                >{{ item.description }}</span
              >
              <div
                class="timeline__details-toggle"
                v-if="item.body && item.body.length > 0"
              >
                <span v-if="!slotProps.active">View Details</span>
                <span v-else>Hide Details</span>
              </div>
            </div>
          </template>
          <template v-slot:body v-if="item.body && item.body.length > 0">
            <div
              class="timeline__item-body"
              v-for="(section, index) in item.body"
              :key="`body-item-${index}`"
            >
              <div class="timeline__item-heading">
                {{ section.heading }}
                <button
                  class="timeline__item-action"
                  v-if="section.action"
                  @click="section.action(section.actionParams || null)"
                >
                  {{ section.actionLabel }}
                </button>
              </div>
              <div
                class="timeline__item-description"
                v-if="section.description"
              >
                {{ section.description }}
              </div>

              <template v-if="section.list">
                <div
                  class="timeline__list-item"
                  v-for="(listItem, index) in section.list"
                  :key="`list-item-${index}`"
                >
                  <p class="timeline__list-item-heading">
                    {{ listItem.label }}
                  </p>
                  <p
                    class="timeline__list-item-sub-heading"
                    v-if="listItem.subLabel"
                  >
                    {{ listItem.subLabel }}
                  </p>
                  <p class="timeline__list-item-description">
                    <span>{{ listItem.description }}</span>
                    <a
                      class="timeline__list-item-link"
                      :href="listItem.url"
                      v-if="listItem.url"
                      target="_blank"
                    >
                      Preview
                    </a>
                  </p>
                  <template v-if="listItem.media">
                    <audio
                      controls
                      :src="listItem.media.url"
                      v-if="listItem.media.type === 'audio'"
                    ></audio>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </SlideToggle>
      </div>
    </template>
    <template v-else> No Conversion Actions </template>
  </div>
</template>

<script>
import dateFormat from "date-fns/format";
import SlideToggle from "@/components/SlideToggle";
import Pill from "@/components/Pill";

export default {
  name: "Timeline",
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    SlideToggle,
    Pill,
  },
  data() {
    return {
      lastSlideToggleOpen: false,
      lastSlideToggleHeight: 0,
    };
  },
  computed: {
    verticalDividerHeight() {
      if (!this.lastSlideToggleOpen) {
        return null;
      }

      return `calc(100% - ${this.lastSlideToggleHeight}px)`;
    },
  },
  methods: {
    getMonthDay(date) {
      if (date) {
        return dateFormat(new Date(date), "MMM d");
      }

      return null;
    },
    getTime(date) {
      if (date) {
        return dateFormat(new Date(date), "h:mm aaa");
      }

      return null;
    },
    onSlideToggle(event, index) {
      if (index === this.items.length - 1) {
        this.lastSlideToggleOpen = event.isOpen;

        // Vertical Divider style override
        // Deduct the slide toggle outer body height from existing height
        this.$nextTick(() => {
          let list = document.querySelectorAll(".slide-toggle");
          let parent = list[list.length - 1];
          let elBody = parent.querySelector(".slide-toggle-body");
          let elHeader = parent.querySelector(".slide-toggle-header");
          this.lastSlideToggleHeight =
            elBody.offsetHeight + elHeader.offsetHeight * 0.5;
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button.scss";

.timeline {
  position: relative;

  &__vertical-divider {
    position: absolute;
    width: 2px;
    height: calc(100% - 36px);
    background: $disabled;
    left: 96px;
  }

  &__horizontal-divider {
    position: absolute;
    height: 2px;
    width: 110px;
    background: $disabled;
    left: 96px;
    top: 38px;
  }

  &__flag {
    position: absolute;
    left: 105px;
    top: 27px;

    .pill {
      width: 94px;
    }
  }

  &__indicator {
    width: 96px;
    padding-right: 32px;
    position: absolute;
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    line-height: 1.75;
    left: 0;
    top: 0;

    &-date {
      font-size: 14px;
      font-weight: 700;
    }

    &-time {
      font-size: 12px;
      color: $medium-gray;
    }
  }

  &__item {
    position: relative;
    margin-bottom: 32px;
    line-height: 1.5;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-heading {
      font-size: 18px;
      color: $dark-blue;
      display: flex;
    }

    &-label {
      font-size: 10px;
      text-transform: uppercase;
      display: inline-block;
      padding: 4px 12px 3px 12px;
      background: $fade-green;
      color: $dark-green;
      border-radius: 12px;

      & + .timeline__item-description {
        margin-left: 1.5rem;
      }
    }

    &-body {
      border-top: solid 2px $fade-gray;
      margin: 0 -1.5rem;
      padding: 0 1.5rem;
      margin-top: 24px;
      padding-top: 24px;

      &:first-of-type {
        border-top: none;
        margin-top: 0;
        padding-top: 0;
      }

      .timeline__item-heading {
        margin-bottom: 12px;
      }
    }

    &-action {
      @extend .button;
      margin-left: auto;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      padding: 4px 12px;
      border: solid 2px $fade-gray;
      background: transparent;
      color: $blue;
      border-radius: 16px;
    }

    &-controls {
      margin-left: auto;
      font-size: 14px;
      text-transform: capitalize;
    }

    .slide-toggle {
      width: calc(100% - 206px);
      margin-left: 206px;
      background: #ffffff;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      &-header {
        height: 76px;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1.5rem;
      }

      &-body {
        padding: 1.5rem;
        border-top: solid 2px $fade-gray;
      }
    }
  }

  &__details-toggle {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    padding-left: 12px;
    border-left: solid 2px $fade-gray;
    margin-left: 12px;
    color: $blue;
  }

  &__list-item {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-heading {
      font-weight: 700;
    }

    &-sub-heading {
      font-size: 12px;
      color: $medium-gray;
      margin-bottom: 6px;
    }

    &-description {
      display: flex;
    }

    &-link {
      margin-left: auto;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      padding: 4px 12px;
      border: solid 2px $fade-gray;
      color: $blue;
      border-radius: 16px;
    }
  }
}
</style>