import nursesData from "@/data/nurses.json";

export const nurses = {
  methods: {
    $_nurses_getOptions() {
      return nursesData.map((item) => {
        return {
          id: item.nurse_id,
          name: `${item.first_name} ${item.last_name}`,
        };
      });
    },
    $_nurses_getNurseById(id) {
      const nurse = nursesData.find((item) => {
        return id === item.nurse_id;
      });

      return nurse;
    },
    $_nurses_getNurseNameById(id) {
      const nurse = this.$_nurses_getNurseById(id);

      if (nurse) {
        return `${nurse.first_name} ${nurse.last_name}`;
      }

      return null;
    },
    $_nurses_getNurseList() {
      return nursesData;
    },
  },
};
