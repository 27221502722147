export const events = {
  computed: {
    events() {
      const events = this.$store.getters["events/events"];
      return events;
    },
  },
  mounted() {
    if (!this.events.length) {
      this.$store.dispatch("events/getEvents");
    }
  },
};
