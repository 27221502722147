<template>
  <div class="conversion">
    <Loading :active="loading" :fullscreen="true" />

    <Modal
      :active="commentModalActive"
      @dismiss="commentModalActive = false"
      class="create-comment-modal form-control"
    >
      <h2>Add a Comment</h2>
      <textarea
        placeholder="Your comment..."
        v-model="currentComment"
      ></textarea>
      <button
        @click="onSaveComment"
        class="button"
        :disabled="!currentComment || currentComment.length === 0"
      >
        Save Comment
      </button>
    </Modal>

    <CallReviewSidebar
      :action_id="selectedConversionAction"
      :active="callReviewActive"
      @dismiss="callReviewActive = false"
      @review-saved="onReviewSaved"
    ></CallReviewSidebar>

    <Modal
      :active="editConversionActive"
      @dismiss="editConversionActive = false"
      class="edit-conversion-modal form-control"
    >
      <h2>Edit Conversion</h2>

      <template v-if="updatedConversion">
        <div class="row">
          <InputField
            label="First Name"
            v-model="updatedConversion.first_name"
          />
          <InputField label="Last Name" v-model="updatedConversion.last_name" />
        </div>
        <InputField label="Email" v-model="updatedConversion.email" />
        <div class="row">
          <InputField label="Zip Code" v-model="updatedConversion.zip_code" />
          <InputField label="Telephone" v-model="updatedConversion.phone" />
        </div>
        <TextareaField label="Message" v-model="updatedConversion.message" />
        <SelectField
          label="Campaign"
          v-model="updatedConversion.campaign_id"
          :options="campaignOptions"
        />
        <div
          v-if="!conversion.campaign_id && updatedConversion.campaign_id"
          class="row"
        >
          <p class="edit-conversion-modal__note">
            Note that you are assigning a campaign to a conversion without one.
            Once saved, this lead will automatically flow through to the contact
            center.
          </p>
        </div>
      </template>

      <div v-if="editErrors" class="edit-conversion-modal__errors">
        <ul>
          <li v-for="(error, index) in editErrors" :key="`edit-error-${index}`">
            {{ error.join(".") }}
          </li>
        </ul>
      </div>

      <button @click="onSaveConversion" class="button">Save Conversion</button>
    </Modal>

    <Modal
      :active="editActionActive"
      @dismiss="editActionActive = false"
      class="edit-conversion-modal form-control"
    >
      <h2>Edit Conversion Action</h2>

      <template v-if="updatedAction">
        <div class="row">
          <SelectField
            label="Nurse"
            v-model="updatedAction.nurse_id"
            :options="nurseOptions"
          />
          <SelectField
            label="Disposition"
            v-model="updatedAction.event_id"
            :options="eventOptions"
          />
        </div>
        <TextareaField
          label="Nurse Notes"
          v-model="updatedAction.nurse_notes"
        />
        <div class="row">
          <div class="input-wrapper edit-conversion-modal__switch">
            <label>Trigger Disposition Events</label>
            <toggle-switch
              :checked="updatedAction.trigger"
              @on-change="updateActionTrigger"
            />
          </div>
        </div>
        <div v-if="updatedAction.trigger" class="row">
          <p class="edit-conversion-modal__note">
            Checking this option will queue any messages attached to this
            disposition for the lead's campaign.
          </p>
        </div>
      </template>

      <div v-if="editErrors" class="edit-conversion-modal__errors">
        <ul>
          <li v-for="(error, index) in editErrors" :key="`edit-error-${index}`">
            {{ error.join(".") }}
          </li>
        </ul>
      </div>

      <button @click="onSaveAction" class="button">
        Save Conversion Action
      </button>
    </Modal>

    <Modal
      :active="viewConversionsActive"
      @dismiss="viewConversionsActive = false"
      class="view-conversions-modal form-control"
    >
      <template v-if="conversion.lead">
        <h2>Related Conversions</h2>
        <table class="styled-table">
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Type</th>
          </tr>
          <tr
            v-for="relatedConversion in conversion.lead.conversions"
            :key="`conversion-${relatedConversion.id}`"
          >
            <td>
              {{ relatedConversion.id }}
              <span v-if="relatedConversion.id === conversion.id">
                Selected
              </span>
            </td>
            <td>
              {{ relatedConversion.created_at | date }}
            </td>
            <td>
              {{ relatedConversion.type | typeFilter }}
            </td>
            <td>
              <strong @click="onSelectConversion(relatedConversion.id)">
                View
              </strong>
            </td>
          </tr>
        </table>
      </template>
    </Modal>

    <div class="hero hero--activate">
      <div class="container">
        <h2>
          {{ fullName }}

          <Pill
            v-if="conversion.lead && conversion.lead.conversions.length > 1"
            :count="conversion.lead.conversions.length"
            :label="true"
            :clickable="true"
            @on-click="toggleConversions"
          />
        </h2>
        <div class="conversion__hero-details">
          <div class="conversion__hero-details-item">
            <div class="conversion__hero-details-item-label">Phone</div>
            <div class="conversion__hero-details-item-description">
              {{ conversion.phone }}
            </div>
          </div>

          <div class="conversion__hero-details-item">
            <div class="conversion__hero-details-item-label">Email</div>
            <div class="conversion__hero-details-item-description">
              {{ conversion.email }}
            </div>
          </div>

          <div class="conversion__hero-details-item">
            <div class="conversion__hero-details-item-label">Zip Code</div>
            <div class="conversion__hero-details-item-description">
              {{ conversion.zip_code }}
            </div>
          </div>

          <template v-if="conversion.lead">
            <div class="conversion__hero-details-item">
              <div class="conversion__hero-details-item-label">Gender</div>
              <div class="conversion__hero-details-item-description">
                {{ conversion.lead.gender | leadGender }}
              </div>
            </div>

            <div class="conversion__hero-details-item">
              <div class="conversion__hero-details-item-label">Age</div>
              <div class="conversion__hero-details-item-description">
                {{ conversion.lead.birth_year | leadAge }}
              </div>
            </div>

            <div class="conversion__hero-details-item">
              <div class="conversion__hero-details-item-label">Lists</div>
              <div class="conversion__hero-details-item-description">
                <Pill
                  :count="conversion.lead.lists.length"
                  :label="true"
                  :clickable="true"
                  @on-click="toggleLists"
                />
              </div>
              <div
                class="card conversion__hero-details-item-window"
                :class="{
                  'conversion__hero-details-item-window--active':
                    toggleListsActive,
                }"
              >
                <div class="tags-wrapper">
                  <Pill
                    v-for="(list, index) in lists"
                    :count="list.name"
                    :key="`list-${index}`"
                    :label="true"
                    :clickable="!list.dynamic"
                    :enabled="listIndex(list) > -1"
                    @on-click="onListClicked(list)"
                  />
                </div>
              </div>
            </div>
          </template>
          <div class="conversion__hero-details-item">
            <button class="button" @click="editConversion">Edit</button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="conversion__content-wrapper card">
        <h2>Conversion Details</h2>
        <div class="conversion__details">
          <div class="conversion__details-item">
            <div class="conversion__details-item-label">Date Created</div>
            <div class="conversion__details-item-description">
              {{ dateCreated }}
            </div>
          </div>

          <div class="conversion__details-item">
            <div class="conversion__details-item-label">Last Active</div>
            <div class="conversion__details-item-description">
              {{ lastActiveDate }}
            </div>
          </div>

          <div class="conversion__details-item">
            <div class="conversion__details-item-label">Nurse</div>
            <div class="conversion__details-item-description">
              {{ nurse | valueOrDash }}
            </div>
          </div>

          <div class="conversion__details-item">
            <div class="conversion__details-item-label">
              Current Disposition
            </div>
            <div class="conversion__details-item-description">
              {{ currentDisposition }}
            </div>
          </div>
        </div>
      </div>

      <div class="conversion__timeline">
        <h2>
          <span>Timeline</span>
          <div class="form-control select-wrapper">
            <select v-model="sortBy">
              <option value="desc">Sort By Newest</option>
              <option value="asc">Sort By Oldest</option>
            </select>
          </div>
        </h2>
        <Timeline :items="timelineItems" />
      </div>
    </div>
  </div>
</template>

<script>
import CallReviewSidebar from "@/components/CallReviewSidebar";
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";
import Timeline from "@/components/Timeline";
import InputField from "@/components/fields/InputField";
import SelectField from "@/components/fields/SelectField";
import TextareaField from "@/components/fields/TextareaField";
import ToggleSwitch from "@/components/ToggleSwitch";
import dateFormat from "date-fns/format";
import { events } from "@/mixins/events";
import { nurses } from "@/mixins/nurses";
import { user } from "@/mixins/user";
import { callReview } from "@/mixins/callReview";
import Pill from "@/components/Pill";

export default {
  name: "Conversion",
  mixins: [events, nurses, user, callReview],
  components: {
    CallReviewSidebar,
    Modal,
    Loading,
    Timeline,
    InputField,
    SelectField,
    TextareaField,
    ToggleSwitch,
    Pill,
  },
  data() {
    return {
      commentModalActive: false,
      editConversionActive: false,
      editActionActive: false,
      selectedConversionAction: null,
      currentComment: null,
      callReviewActive: false,
      sortBy: "desc",
      updatedConversion: null,
      updatedAction: null,
      editErrors: null,
      toggleListsActive: false,
      viewConversionsActive: false,
    };
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_REMOTE_FRONTEND_BASE;
    },
    lists() {
      return this.$store.getters["lists/lists"];
    },
    loading() {
      return this.$store.getters["conversions/loading"];
    },
    conversion() {
      return this.$store.getters["conversions/conversion"];
    },
    fullName() {
      if (this.conversion) {
        if (
          !this.conversion.first_name &&
          this.conversion.type == "lead_created_phone"
        ) {
          return "Inbound Lead";
        }
        return `${this.conversion.first_name} ${this.conversion.last_name}`;
      }

      return null;
    },
    currentDisposition() {
      if (this.conversion && this.conversion.disposition) {
        return this.conversion.disposition;
      }

      return "-";
    },
    dateCreated() {
      if (this.conversion && this.conversion.created_at) {
        return dateFormat(new Date(this.conversion.created_at), "MMM d, Y");
      }

      return "-";
    },
    lastActiveDate() {
      if (this.conversion && this.conversion.last_action_date) {
        return dateFormat(
          new Date(this.conversion.last_action_date),
          "MMM d, Y"
        );
      }

      return "-";
    },
    eventOptions() {
      if (this.events) {
        return this.events
          .filter((event) => {
            return (
              event.external_name.indexOf("Created") === -1 &&
              event.external_name !== "Lead Rescheduled"
            );
          })
          .map((event) => {
            return {
              value: event.id,
              label: event.external_name,
            };
          });
      }

      return [];
    },
    nurse() {
      if (this.conversion && this.conversion.nurse_id) {
        return this.$_nurses_getNurseNameById(this.conversion.nurse_id);
      }

      return "-";
    },
    nurseOptions() {
      return this.$_nurses_getNurseList().map((nurse) => {
        return {
          value: nurse.nurse_id,
          label: this.$_nurses_getNurseNameById(nurse.nurse_id),
        };
      });
    },
    responses() {
      if (this.conversion.responses && this.conversion.responses.length) {
        return this.conversion.responses.map((response) => {
          return {
            label: response.question.label,
            description: response.value,
            type: response.assessment.type,
          };
        });
      }

      return null;
    },
    assessmentResponses() {
      if (this.responses) {
        return this.responses.filter((response) => {
          return response.type === "screener";
        });
      }

      return null;
    },
    surveyResponses() {
      if (this.responses) {
        return this.responses.filter((response) => {
          return response.type === "survey";
        });
      }

      return null;
    },
    actions() {
      if (this.conversion) {
        // Reorder the conversion actions
        let conversionActions = this.conversion.actions;
        conversionActions = conversionActions.sort((a, b) => {
          if (this.sortBy === "desc") {
            return new Date(b.data.date) - new Date(a.data.date);
          }

          return new Date(a.data.date) - new Date(b.data.date);
        });

        // Format Conversion Actions
        return conversionActions.map((action) => {
          let action_label = null;
          if (action.reviewed) {
            if (this.getReviewEmpty(action.review)) {
              action_label = "Review Skipped";
            } else {
              action_label = "Reviewed";
            }
          }
          let call_direction = null;
          if (action.call_direction === "inbound") {
            call_direction = `← ${action.call_direction}`;
          }
          if (action.call_direction === "outbound") {
            call_direction = `${action.call_direction} →`;
          }
          let content = {
            date: action.data.date + " UTC",
            heading: action.data.disposition,
            action_id: action.id,
            description: action.data.call_id
              ? "Source: Phone"
              : "Source: Unidentified",
            label: action_label,
            flag: call_direction,
            body: [],
          };

          // Add nurse
          if (action.data.nurse_id && action.data.nurse_id != "") {
            content.body.push({
              heading: "Nurse",
              description:
                this.$_nurses_getNurseNameById(action.data.nurse_id) || "-",
            });
          }

          // Add nurse notes
          if (action.data.nurse_notes && action.data.nurse_notes != "") {
            content.body.push({
              heading: "Nurse Notes",
              description: action.data.nurse_notes,
            });
          }

          // Add nurse capture questions
          if (action.data.nurse_capture_questions) {
            let list = [];
            action.data.nurse_capture_questions.map((question) => {
              if (question.response && question.response != "") {
                list.push({
                  label: question.question,
                  description: question.response,
                });
              }
            });

            if (list.length) {
              content.body.push({
                heading: "Nurse Capture Questions",
                list: list,
              });
            }
          }

          // Add recordings
          if (action.recordings && action.recordings.length) {
            let list = [];
            action.recordings.map((recording) => {
              if (recording.url) {
                list.push({
                  label: null,
                  description: null,
                  media: {
                    type: "audio",
                    url: recording.url,
                  },
                });
              }
            });

            if (list.length) {
              content.body.push({
                heading: "Recordings",
                list: list,
                action: this.openReviewSidebar,
                actionLabel: "Call Review",
                actionParams: action.id,
              });

              let notes = [];
              action.notes.map((note) => {
                notes.push({
                  label: note.name,
                  subLabel: dateFormat(new Date(note.created_at), "MMM d, Y"),
                  description: note.notes,
                });
              });

              content.body.push({
                heading: "Comments",
                list: notes,
                action: this.openCommentsModal,
                actionLabel: "Add Comment",
                actionParams: action.id,
              });
            }
          }

          // Add survey responses
          if (this.surveyResponses && this.surveyResponses.length) {
            content.body.push({
              heading: "Survey Responses",
              list: this.surveyResponses,
            });
          }

          content.body.push({
            heading: "Edit Conversion Action",
            action: this.openActionModal,
            actionLabel: "Edit",
            actionParams: action.id,
          });

          return content;
        });
      }

      return null;
    },
    leadCreationContent() {
      if (this.conversion) {
        // Format Lead Creation
        let content = {
          date: this.conversion.created_at,
          heading: "Lead Created",
          description: this.conversion?.session?.ad?.source
            ? `Origin: ${this.conversion.session.ad.source}`
            : "Origin: Unidentified",
          body: [
            {
              heading: "Marketing",
              list: [
                {
                  label: "Campaign",
                  description: this.conversion?.session?.campaign?.name || "-",
                },
                {
                  label: "Ad",
                  description: this.conversion?.session?.ad?.name || "-",
                  url: this.conversion?.session?.ad?.preview_url || null,
                },
                {
                  label: "Lander",
                  description: this.conversion?.session?.view?.name || "-",
                  url: this.conversion.session.view
                    ? `${this.baseURL}/${this.conversion.session.campaign.slug}/${this.conversion.session.view.slug}`
                    : null,
                },
              ],
            },
          ],
        };

        // If appointment date exists, add this section
        if (this.conversion.appointment_date) {
          let appointment_date = dateFormat(
            new Date(
              `${this.conversion.appointment_date} ${this.conversion.appointment_time}`
            ),
            "MMM d, Y"
          );
          let appointment_start = dateFormat(
            new Date(
              `${this.conversion.appointment_date} ${this.conversion.appointment_time}`
            ),
            "haaa"
          );
          let appointment_end = dateFormat(
            new Date(
              `${this.conversion.appointment_date} ${this.conversion.appointment_time_end}`
            ),
            "haaa"
          );
          content.body.push({
            heading: "Appointment Time",
            description: `${appointment_date} between ${appointment_start} and ${appointment_end}`,
          });
        }

        // If message exists, add this section
        if (this.conversion.message) {
          content.body.push({
            heading: "Message",
            description: this.conversion.message,
          });
        }

        // Add assessment responses to lead creation content
        if (this.assessmentResponses && this.assessmentResponses.length) {
          content.body.push({
            heading: "Assessment Responses",
            list: this.assessmentResponses,
          });
        }

        return content;
      }

      return null;
    },
    timelineItems() {
      if (this.sortBy === "desc") {
        return [...this.actions, this.leadCreationContent];
      }

      return [this.leadCreationContent, ...this.actions];
    },
    reviewEmpty() {
      let is_empty = true;

      this.review.responses.forEach((response) => {
        if (response.response !== null) {
          is_empty = false;
        }
      });

      if (
        this.review.medical_infraction ||
        this.review.language_infraction ||
        this.review.other_infraction
      ) {
        is_empty = false;
      }

      return is_empty;
    },
    campaignOptions() {
      return this.$store.getters["conversions/campaigns"].map((campaign) => {
        return {
          value: campaign.id,
          label: campaign.name,
        };
      });
    },
  },
  methods: {
    onSelectConversion(conversionId) {
      let routeData = this.$router.resolve({
        name: "conversion",
        params: { id: conversionId },
      });
      window.open(routeData.href, "_blank");
    },
    openCommentsModal(id) {
      this.currentComment = null;
      this.selectedConversionAction = id;
      this.commentModalActive = true;
    },
    onSaveComment() {
      this.$store
        .dispatch("conversions/addCallNote", {
          conversion_action_id: this.selectedConversionAction,
          notes: this.currentComment,
          email: this.$_user_email,
          name: this.$_user_name,
        })
        .then((res) => {
          if (res.data) {
            this.selectedConversionAction = null;
            this.currentComment = null;
            this.commentModalActive = false;
          }
        });
    },
    openReviewSidebar(id) {
      this.selectedConversionAction = id;
      this.callReviewActive = true;
    },
    onReviewSaved() {
      this.callReviewActive = false;
      this.getConversion();
    },
    getConversion() {
      this.$store.dispatch("conversions/getConversion", {
        conversionId: this.$route.params.id,
      });
    },
    getReportingData() {
      this.$store.dispatch("conversions/getReportingData");
    },
    editConversion() {
      this.updatedConversion = { ...this.conversion };
      this.editConversionActive = true;
    },
    onSaveConversion() {
      this.editErrors = null;
      this.$store
        .dispatch("conversions/updateConversion", this.updatedConversion)
        .then(() => {
          this.editConversionActive = false;
          this.flashMessage.success({
            title: "Conversion updated",
          });
        })
        .catch((err) => {
          if (err && err.response?.data) {
            this.editErrors = err.response?.data?.error;
          } else {
            this.editErrors = null;
          }
        });
    },
    onSaveAction() {
      this.editErrors = null;
      this.$store
        .dispatch("conversions/updateConversionAction", this.updatedAction)
        .then((res) => {
          this.editActionActive = false;
          this.flashMessage.success({
            title: "Conversion Action updated",
          });

          if (res.data) {
            this.getConversion();
          }
        })
        .catch((err) => {
          if (err && err.response?.data) {
            this.editErrors = err.response?.data?.error;
          } else {
            this.editErrors = null;
          }
        });
    },
    openActionModal(action_id) {
      const filtered_actions = this.conversion.actions.filter((action) => {
        return action.id === action_id;
      });

      if (filtered_actions.length) {
        const action = filtered_actions[0];
        this.updatedAction = {
          id: action.id,
          event_id: action.event_id,
          nurse_id: action.data.nurse_id,
          nurse_notes: action.data.nurse_notes,
          trigger: false,
        };
        this.editActionActive = true;
      }
    },
    updateActionTrigger() {
      this.updatedAction.trigger = !this.updatedAction.trigger;
    },
    onListClicked(list) {
      const i = this.listIndex(list);
      if (i === -1) {
        this.conversion.lead.lists.push(list);
      } else {
        this.conversion.lead.lists.splice(i, 1);
      }

      this.$store.dispatch("lists/updateLeadLists", {
        lead_id: this.conversion.lead.id,
        lists: this.conversion.lead.lists,
      });
    },
    listIndex(list) {
      if (this.conversion.lead.lists && this.conversion.lead.lists.length) {
        return this.conversion.lead.lists.findIndex((item) => {
          return item.id === list.id;
        });
      }

      return -1;
    },
    toggleLists() {
      this.toggleListsActive = !this.toggleListsActive;
    },
    toggleConversions() {
      this.viewConversionsActive = true;
    },
  },
  mounted() {
    this.$store.dispatch("lists/getLists");
    this.getReportingData();
    this.getConversion();
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/hero";
@import "@/scss/forms";
@import "@/scss/table";

.view-conversions-modal {
  span {
    color: $green;
    font-weight: bold;
  }
  strong {
    color: $light-blue;
    cursor: pointer;
  }
}

.hero__back {
  cursor: pointer;
  display: block;
  font-size: 10px;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.sidebar__footer {
  padding: 0 1rem !important;

  .button {
    margin-left: auto;
  }
}

.conversion {
  &__hero-details {
    margin-left: auto;
    display: flex;

    &-item {
      margin-left: 48px;
      position: relative;

      &-label {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 700;
      }

      &-description {
        margin-top: 12px;
      }
    }

    &-item-window {
      display: none;
      margin-top: 1rem;
      padding: 1.5rem !important;
      position: absolute;
      right: 0;
      top: 100%;
      width: 500px;
    }

    &-item-window--active {
      display: block;
    }
  }

  .tags-wrapper {
    .pill {
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
      opacity: 0.65;

      &.clickable {
        opacity: 1;
      }
    }
  }

  &__content-wrapper {
    background: #ffffff;
    border-radius: 8px;
    padding: 2rem;
  }

  &__details {
    background: $fade-gray;
    padding: 1.5rem;
    display: flex;
    border-radius: 8px;

    &-item {
      display: inline-flex;
      align-items: center;
      flex-grow: 1;

      &-label {
        font-size: 10px;
        text-transform: uppercase;
        margin-right: 12px;
        color: $medium-gray;
        font-weight: 700;
      }

      &-description {
        color: $dark-blue;
      }
    }
  }

  &__timeline {
    margin-top: 2rem;

    h2 {
      width: 100%;
    }

    select {
      margin-bottom: 0 !important;
    }

    .select-wrapper {
      margin-left: auto;
      min-width: 192px;
    }
  }
}

.create-comment-modal {
  &__skip {
    cursor: pointer;
    margin-left: 16px;
    opacity: 0.75;
  }
}

.edit-conversion-modal {
  &__note {
    font-style: italic;
    margin-bottom: 1rem;
  }

  &__switch {
    margin-bottom: 1rem;
  }

  &__errors {
    margin-bottom: 1rem;

    ul {
      list-style-type: disc;
    }

    li {
      color: $dark-red;
    }
  }

  .toggle-switch {
    margin-left: 1.25rem !important;
    .slider {
      background-color: $fade-gray;
    }
    input:checked + .slider {
      background-color: $dark-blue;
    }
  }
}
</style>