<template>
  <div class="modal">
    <transition name="fade">
      <div class="modal-overlay" v-if="active" @click="$emit('dismiss')">
        <button class="modal-overlay__button">
          <BaseSVG :src="require('@/assets/close-icon.svg')" />
        </button>
      </div>
    </transition>

    <transition name="fade-up">
      <section class="modal-wrapper" v-if="active">
        <div class="modal-content">
          <slot />
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";

export default {
  name: "Modal",
  props: ["active"],
  components: {
    BaseSVG,
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.modal {
  &-overlay,
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
  }

  &-overlay {
    background: $dark-blue-overlay;

    &__button {
      background: transparent;
      border: none;
      padding: 0;
      font-family: inherit;
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;

      svg {
        fill: #fff;
        width: 1rem;
      }
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  &-header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  &-content {
    pointer-events: auto;
    width: 512px;
    max-height: 90%;
    background: #fff;
    box-shadow: 0 32px 64px $dark-blue-overlay;
    padding: 2rem;
    overflow-y: auto;
    border-radius: 8px;
  }
}
</style>