<template>
  <div class="input-wrapper">
    <label>{{ label }}</label>
    <input
      type="text"
      :value="value"
      @input="$emit('update', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: ["value", "label"],
  model: {
    prop: "value",
    event: "update",
  },
};
</script>
