<template>
  <label class="toggle-switch" :class="{ 'toggle-switch--small': small }">
    <input type="checkbox" :checked="checked" @change="onChange" />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  name: "ToggleSwitch",
  props: ["checked", "small"],
  methods: {
    onChange(e) {
      this.$emit("on-change", e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/colors";

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $dark-blue;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.25);
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }

  &.toggle-switch--small {
    width: 30px;
    height: 17px;

    input {
      &:checked + .slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
      }
    }
    .slider {
      &:before {
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 2px;
      }

      &.round {
        border-radius: 17px;
      }
    }
  }
}
</style>