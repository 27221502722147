<template>
  <div
    class="slide-toggle"
    :class="{ active, 'slide-toggle--disabled': disabled }"
  >
    <header class="slide-toggle-header" @click="onClick">
      <slot name="header" v-bind:active="active" />
    </header>
    <div class="slide-toggle-body" v-if="active && !disabled">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideToggle",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.active = !this.active;
        this.$emit("on-slide-toggle", { isOpen: this.active });
      }
    },
  },
};
</script>

<style lang="scss">
.slide-toggle {
  &-header {
    cursor: pointer;
  }
  &-body {
    overflow: hidden;
  }

  &--disabled {
    .slide-toggle-header {
      cursor: default;
    }
  }
}
</style>