<template>
  <div class="input-wrapper">
    <label>{{ label }}</label>
    <select
      :value="value"
      @change="$emit('update', $event.target.value)"
      :disabled="disabled"
    >
      <option :value="null" disabled>Select</option>
      <option
        v-for="(option, index) in options"
        :key="`option-${index}`"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ["value", "label", "options", "disabled"],
  model: {
    prop: "value",
    event: "update",
  },
};
</script>
